<template>
    <div id="login" class="login">
        <b-loading :is-full-page="true" :active.sync="IsLoading" :can-cancel="false" />
    </div>
</template>

<script>
import Helpers from "@/utils/Helpers"
import { LOGIN_TOKEN_RECEIVE, LOGOUT_REQUEST } from "@/store/actions/auth"

export default {
    name: "LoginPage",
    data() {
        return {
            IsLoading: true
        }
    },
    mounted() {
        if (
            this.$route
            && this.$route.query
            && this.$route.query.AuthToken
            && this.$route.query.ExpirationDate
        ) {
            this.$store
                .dispatch(LOGIN_TOKEN_RECEIVE, {
                    AuthToken: this.$route.query.AuthToken,
                    ExpirationDate: this.$route.query.ExpirationDate
                })
                .then(() => this.$router.push({ name: "Dashboard" }))
                .catch(() => this.$store.dispatch(LOGOUT_REQUEST))
        }
        else {
            Helpers.notify("is-danger", this.$i18n.t("Message.Backend.NoData"))
        }
    }
}
</script>
